<template>
  <Menubar />
  <div class="a-content">
    <div class="a-breadcrumb">
      <span>{{ breadcrumb }}</span>
    </div>

    <div class="a-main-content">
      <div class="a-btn-content">
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </div>

    <div id="a-admin-footer">
      <span>
        <strong
          >Desarrollado por la Dirección de Sistemas Tecnológicos e Informáticos
        </strong>
      </span>
      <br />
      <span>
        <strong>Secretaria de las TIC</strong>
        - Villavicencio Cambia Contigo
      </span>
    </div>
  </div>
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
export default {
  components: {
    Menubar,
  },
  computed: {
    breadcrumb() {
      return this.$route.meta.display;
    },
  },
};
</script>

<style>
.a-btn-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.a-btn-content .a-main-btn {
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.a-btn-content .p-inputtext {
  max-height: 48px;
}

.a-btn-content .a-icon-btn div {
  margin: 0;
}
</style>

<style scoped>
.a-content {
  height: calc(100% - 86px);
  width: calc(100% - 86px);
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 40px;
}

.a-content .a-breadcrumb {
  margin: 5px 0;
  text-align: end;
  color: var(--color-2);
  font-weight: 600;
  padding-right: 30px;
  font-size: 15px;
}

.a-main-content {
  background: var(--color-4);
  width: 100%;
  height: calc(100% - 80px);
  border-radius: 20px;
  overflow: auto;
  padding: 30px;
}

#a-admin-footer {
  margin: 10px 0;
  font-family: Montserrat;
  font-size: 10px;
  letter-spacing: -0.1px;
  text-align: center;
}
</style>
