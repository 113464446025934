<template>
  <div id="a-sidebar">
    <button
      :class="activeRoute == 'Dashboard' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/')"
    >
      <div>
        <img :src="require('@/assets/icons/dashboard.svg')" />
      </div>
    </button>
    <button
      :class="activeRoute == 'Reports' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/reportes')"
    >
      <div>
        <img :src="require('@/assets/icons/reports.svg')" />
      </div>
    </button>
    <button
      :class="activeRoute == 'Users' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/usuarios')"
      v-if="usertype.name == 'Administrador'"
    >
      <div>
        <img :src="require('@/assets/icons/user.svg')" />
      </div>
    </button>
    <button
      :class="
        activeRoute == 'Secretaries' ? 'a-active a-icon-btn' : 'a-icon-btn'
      "
      @click="$router.push('/secretarias')"
      v-if="usertype.name == 'Administrador'"
    >
      <div>
        <img :src="require('@/assets/icons/secretaries.svg')" />
      </div>
    </button>
    <button
      :class="
        activeRoute == 'IncidentTypes' ? 'a-active a-icon-btn' : 'a-icon-btn'
      "
      @click="$router.push('/tipos/incidente')"
      v-if="usertype.name == 'Administrador'"
    >
      <div>
        <img :src="require('@/assets/icons/incidents.svg')" />
      </div>
    </button>
    <button
      :class="activeRoute == 'UserTypes' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/tipos/usuario')"
      v-if="usertype.name == 'Administrador'"
    >
      <div>
        <img :src="require('@/assets/icons/usertypes.svg')" />
      </div>
    </button>
    <button
      :class="activeRoute == 'History' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/historial')"
      v-if="usertype.name == 'Administrador'"
    >
      <div>
        <img :src="require('@/assets/icons/clock.svg')" />
      </div>
    </button>
  </div>
  <Toast />
</template>

<script>
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    isSidebarOpened() {
      return this.$store.state.isSidebarOpened;
    },
    activeRoute() {
      return this.$route.name;
    },
    usertype() {
      if (
        this.$store.state.user != null &&
        this.$store.state.user.userType != null
      ) {
        return this.$store.state.user.userType;
      } else {
        return "Editor";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
#a-sidebar {
  width: 86px;
  height: calc(100% - 86px);
  background: var(--text-color);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#a-sidebar .a-icon-btn {
  margin: 0 0 20px 0;
  padding: 0;
}
#a-sidebar .a-icon-btn div {
  background: white;
}
#a-sidebar .a-icon-btn img {
  height: 24px;
  width: 24px;
}
.a-active {
  width: 100%;
  position: relative;
}

.a-active-rounded-border {
  position: absolute;
  width: 4px;
  right: -2px;
  margin: 0;
}

.a-active div {
  background: var(--color-2) !important;
}

.a-active img {
  filter: brightness(0) invert(1);
}
</style>
