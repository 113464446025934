<template>
  <Menubar>
    <template #start>
      <div id="a-menu-btn">
        <img :src="require('@/assets/icons/menu-green.svg')" height="24" />
      </div>
      <div>
        <img
          :src="require('@/assets/images/logo/villavo-white.svg')"
          height="40"
        />
      </div>
    </template>
    <template #end>
      <button class="a-icon-btn" @click="logout">
        <div>
          <img
            :src="require('@/assets/icons/turn-off-white.svg')"
            height="24"
          />
        </div>
        <span>Salir</span>
      </button>
    </template>
  </Menubar>
  <Sidebar />
</template>

<script>
import Sidebar from "@/components/navigation/Sidebar.vue";
export default {
  name: "TopBar",
  components: {
    Sidebar,
  },
  methods: {
    logout() {
      sessionStorage.removeItem("cfta");
      sessionStorage.removeItem("cftr");
      this.$store.commit("setUser", null);
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style>
.p-menubar {
  /* padding: 0.5rem 1rem; */
  padding: 0;
  background: var(--color-1);
  border-radius: 0;
  color: white;
  min-height: 76px;
}

.p-menubar #a-menu-btn {
  min-height: 86px;
  min-width: 86px;
  padding: 1rem;
  background: var(--text-color);
  margin-right: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-menubar-start {
  display: flex;
  align-items: center;
}

.p-menubar-end {
  margin-right: 40px;
}
</style>
